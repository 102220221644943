import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { RemixBrowser } from '@remix-run/react'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import React from 'react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { getInitialNamespaces } from 'remix-i18next'

import { initDatadog } from '~/utils/datadog'

import i18n, { customFormatters } from './i18n'

const enableBugsnag = window.ENV?.enableBugsnag
const enableDatadogRum = window.ENV?.enableRum

if (enableBugsnag && window.ENV) {
  Bugsnag.start({
    // TODO: use a better convention for browser-only env creds
    // TODO: this cred is duplicated in package.json
    apiKey: '0d43e664c9bc62411d3a635aaf5383b9',
    plugins: [new BugsnagPluginReact()],
    appVersion: window.ENV.version,
    releaseStage: window.ENV.pod
  })
}

if (enableDatadogRum && window.ENV) {
  initDatadog({
    version: window.ENV.version,
    env: window.ENV.pod
  })
}

const ErrorBoundary = (enableBugsnag && Bugsnag.getPlugin('react')?.createErrorBoundary(React)) || React.Fragment

async function hydrate() {
  await i18next
    .use(initReactI18next) // Tell i18next to use the react-i18next plugin
    .use(Backend) // Setup your backend
    .init({
      ...i18n, // spread the configuration
      // This function detects the namespaces your routes rendered while SSR use
      ns: getInitialNamespaces(),
      backend: {
        loadPath: '/app/api/locale/{{lng}}',
        // Create a new query string param to bust the cache
        queryStringParams: {
          v: window.__remixManifest.version,
          translationsUpdatedAt: window.ENV?.translationsUpdatedAt
        }
      },
      postProcess: ['inline-editor'],
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ['htmlTag'],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: []
      }
    })

  customFormatters.forEach((formatter) => {
    i18next.services.formatter?.add(formatter.name, formatter.format)
  })

  startTransition(() => {
    hydrateRoot(
      document,
      <ErrorBoundary>
        <I18nextProvider i18n={i18next}>
          <StrictMode>
            <RemixBrowser />
          </StrictMode>
        </I18nextProvider>
      </ErrorBoundary>
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}
