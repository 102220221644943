import { capitalize } from '~/utils/string'

const options = {
  // This is the list of languages your application supports
  supportedLngs: ['en'],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: 'en',
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: 'common',
  // Disabling suspense is recommended
  react: { useSuspense: false },

  interpolation: {
    escapeValue: false
  },

  //
  returnNull: false
}

interface Formatter {
  name: string
  format: (value: string) => string
}

export const customFormatters: Formatter[] = [
  {
    name: 'capitalize',
    format: capitalize
  }
]

export default options
